import React from 'react';
import Icon from '@ant-design/icons';
import Badge from 'components/Badge/Badge';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import Logo from 'components/navigation/Menu/Logo';
import OpenInAppBtn from './index';
import { ReactComponent as AlertsOutline } from 'style/_assets/icons/alerts-outline.svg';
import { ReactComponent as AlertsFilled } from 'style/_assets/icons/alerts-filled.svg';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { Link } from 'components/navigation/Link/Link';
import { ReactComponent as AlertsOutlineWhite } from 'style/_assets/icons/alerts-outline-white.svg';
import { ReactComponent as HamburgerButton } from 'style/_assets/icons/menu.svg';

const GlobalHeaderWithOpenInAppBtn = props => {
  const {
    currentPath,
    eventHandler,
    isBoosterWaitlistPage,
    isPremiumUser,
    loggedIn,
    logoLink,
    isSignup,
    totalUnreadCount,
    isPlusUser,
    onMenuToggle,
    notifications,
  } = props;

  const onClickBell = () =>
    props.eventHandler(CLICK, {
      'Click Type': 'Global Header Notification Bell',
    });

  const NotificationSection = () => {
    if (totalUnreadCount === 0) {
      return isPlusUser ? (
        <Icon className="text-24" component={AlertsOutlineWhite} />
      ) : (
        <Icon className="text-24" component={AlertsOutline} />
      );
    } else {
      return (
        <>
          <Badge className="pos-abs" style={{ margin: '-16px -18px 0 0' }} />
          <Icon className="text-24" component={AlertsFilled} />
        </>
      );
    }
  };

  return (
    <FlexContainer
      className={`d-hide justify-between t-width100 items-align-center ${
        isPlusUser ? 'continue-to-app-banner-plus' : 'continue-to-app-banner'
      }`}
    >
      <FlexContainer alignItemsCenter className="m-padL16 m-hamburger ">
        <a href="##" onClick={onMenuToggle} data-testid="headerLoggedIn-mobile">
          <FlexContainer className="mobileMenuItemWrapper">
            <FlexContainer
              className={`menuDotIconSection ${
                isPlusUser ? 'menuDotPlusIconSection' : ''
              }`}
            >
              {notifications && (
                <Badge
                  type="dot"
                  className="pos-abs"
                  style={{ right: '0', top: '-4px' }}
                />
              )}
              <HamburgerButton />
            </FlexContainer>
          </FlexContainer>
        </a>
      </FlexContainer>
      <div className="left">
        <Logo
          showLogo={true}
          eventHandler={eventHandler}
          loggedIn={loggedIn}
          logoLink={logoLink}
          currentPath={currentPath}
          isBoosterWaitlistPage={isBoosterWaitlistPage}
          isPremiumUser={isPremiumUser}
          isSignup={isSignup}
          isPlusUser={isPlusUser}
        />
      </div>
      <Link
        onClickCallback={onClickBell}
        to="/alerts"
        data-testid={`header-alerts-link`}
        activeClassName="selected"
        className="pos-rel margR10"
      >
        <FlexContainer className="justify-center format100 items-align-center">
          <NotificationSection />
        </FlexContainer>
      </Link>
      <div className="mobile-right m-padR20">
        <OpenInAppBtn />
      </div>
    </FlexContainer>
  );
};

export default GlobalHeaderWithOpenInAppBtn;
