import React from 'react';
import { Link } from 'components/navigation/Link/Link';
import { getPublicSiteUrl } from 'helpers/env';
import classnames from 'classnames';
import { ReactComponent as CSLogo } from 'style_assets/logos/CS_Logo_Blue.svg';
import { ReactComponent as CSLogoPremium } from 'style_assets/logos/CS_Logo_Premium.svg';
import { ReactComponent as CSLogoPlus } from 'style_assets/logos/CSPlusLogo_White.svg';
import { ReactComponent as CSLogoSmall } from 'style_assets/logos/csLogo-small.svg';
import { ReactComponent as CSLogoSmallPremium } from 'style_assets/logos/csLogoPremium-small.svg';
import { ReactComponent as CSLogoSmallPlus } from 'style_assets/logos/csLogoPlus-small.svg';
import { LOGO_MSG } from '../constants/Menu.constants';

function Logo({
  loggedIn,
  isSignup,
  eventHandler,
  showLogo,
  logoLink,
  currentPath,
  isBoosterWaitlistPage,
  isPremiumUser,
  isPlusUser,
}) {
  const isSignup2 = currentPath && currentPath.indexOf('signup2') !== -1;
  let isLoginPage = logoLink === '/login' ? true : false;
  let extraLogoClassNames = classnames({
    'no-pointer': isSignup2 && !isBoosterWaitlistPage,
    secure: loggedIn && !showLogo,
    public: !(loggedIn && !showLogo),
    'padL12 padT6 premium-logo': isPremiumUser,
    'padL12 padT6 plus-logo': isPlusUser,
  });
  const homeUrl = getPublicSiteUrl();

  return (
    <>
      {isLoginPage || isSignup ? (
        <a href={homeUrl}>
          <nav className={`navbar-logo ${extraLogoClassNames}`}>
            <CSLogo aria-label={LOGO_MSG} role="img" />
          </nav>
        </a>
      ) : (
        <Link to={logoLink} eventHandler={eventHandler} navItem="logo">
          <nav className={`navbar-logo ${extraLogoClassNames}`}>
            {isPlusUser ? (
              <>
                <CSLogoPlus
                  aria-label={LOGO_MSG}
                  role="img"
                  className="margT5 cslogoPlus-default"
                />
                <CSLogoSmallPlus
                  aria-label={LOGO_MSG}
                  role="img"
                  className="margT5 cslogoPlus-msmall"
                />
              </>
            ) : isPremiumUser ? (
              <>
                <CSLogoPremium
                  className="cslogoPremium-default"
                  aria-label={LOGO_MSG}
                  role="img"
                />
                <CSLogoSmallPremium
                  className="cslogoPremium-msmall"
                  aria-label={LOGO_MSG}
                  role="img"
                />
              </>
            ) : (
              <>
                <CSLogo
                  className="cslogo-default"
                  aria-label={LOGO_MSG}
                  role="img"
                />
                <CSLogoSmall
                  className="cslogo-msmall"
                  aria-label={LOGO_MSG}
                  role="img"
                />
              </>
            )}
          </nav>
        </Link>
      )}
    </>
  );
}

export default Logo;
