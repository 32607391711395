import { useConfig } from './config.hook';

export const useSubscriptionLandingUrl = props => {
  const { config, configLoading } = useConfig();
  const { subscriptionLandingUrl } = config ?? {};
  const reactivateUrl = subscriptionLandingUrl + '/reactivate';
  return {
    subscriptionLandingUrl,
    reactivateUrl,
    configLoading,
  };
};
