import React from 'react';
import { useConfig, useConfigLoader } from 'hook/config.hook';

export const withConfig = Cmp => {
  const WithConfigHoC = props => {
    const { config, configLoading, configError } = useConfig();

    return (
      <Cmp
        {...props}
        config={config}
        configLoading={configLoading}
        configError={configError}
      />
    );
  };

  return WithConfigHoC;
};

export const withConfigLoader = Cmp => {
  const WithConfigLoaderHoC = props => {
    const { config, configLoading, configError } = useConfigLoader();

    return (
      <Cmp
        {...props}
        config={config}
        configLoading={configLoading}
        configError={configError}
      />
    );
  };

  return WithConfigLoaderHoC;
};
