import { PLUS_LABEL_MAP } from 'types/plus.constants';
import { useFlag } from './flag.hook';
import { isNonControlVariationLoadedAndEnabled } from 'helpers/optimizely.helper';
import { isFreemium, isPlus } from 'helpers/userHelper';
import { evaluateHybridState } from 'helpers/creditReportHelper';

export const usePlusLaunch = props => {
  const plusLaunchWebFlag = useFlag('pluslaunch_web');
  const isPlusLaunchWebFlagEnabled =
    isNonControlVariationLoadedAndEnabled(plusLaunchWebFlag);
  const { plusLaunch } = evaluateHybridState(props);
  let textMapped =
    isPlus(props.userInfo) ||
    (isPlusLaunchWebFlagEnabled && isFreemium(props.userInfo))
      ? PLUS_LABEL_MAP['CS']
      : PLUS_LABEL_MAP[props?.defaultPremium ?? 'PREMIUM'];
  if (props?.fromNativeApp) {
    textMapped =
      isPlus(props.userInfo) || (plusLaunch && isFreemium(props.userInfo))
        ? PLUS_LABEL_MAP['CS']
        : PLUS_LABEL_MAP[props?.defaultPremium ?? 'PREMIUM'];
  }
  return {
    data: plusLaunchWebFlag,
    plusLaunchEnabled: isPlusLaunchWebFlagEnabled,
    textMapped,
    plusLaunch,
  };
};
