import { cmAlertTypes, cmAlertNamesMapping } from 'types/cm.alert.constants';
import { formatPhoneNumber } from 'helpers/signup.helpers';
import { alertScoreFactorsCodesMap } from 'types/alertScoreFactorsCodes';
import { isUserEnrolledInCash } from 'helpers/tracking.helpers';
import { convertTUDatetoFormattedDate, getDaysSince } from 'helpers/dateHelper';
import { formatAddress } from 'helpers/address.helper';
import { formatIncome } from 'helpers/money.helper';

export const showCreditProfileRefreshTag = category => {
  return (
    category &&
    [
      cmAlertTypes.NEW_ACCOUNT,
      cmAlertTypes.PAID_TRADELINE,
      cmAlertTypes.ACCOUNT_CHARGE_OFF,
      cmAlertTypes.PAID_COLLECTION,
      cmAlertTypes.DECREASE_IN_COLLECTION_BALANCES,
      cmAlertTypes.MISSING_CARD,
      cmAlertTypes.BANKRUPTCY,
      cmAlertTypes.FORCLOSURE,
      cmAlertTypes.RETAIL_CARD_OVERLIMIT,
      cmAlertTypes.CARD_OVER_LIMIT,
      cmAlertTypes.INCREASE_IN_COLLECTION_BALANCES,
      cmAlertTypes.CLOSED_ACCOUNT,
      cmAlertTypes.COLLECTIONS_EXCLUDING_MEDICAL,
      cmAlertTypes.IMPROVED_ACCOUNT,
    ].indexOf(category) >= 0
  );
};

export const unreadAlertsCount = (state, days) => {
  const { cmAlerts, csidAlerts } = state;
  if (!cmAlerts && !csidAlerts) {
    return 0;
  }

  function checkAlertUnread(alert) {
    if (alert.viewed) {
      return false;
    }
    if (!days) {
      return true;
    }
    const daysSince = getDaysSince(alert.createDate);
    return daysSince <= days;
  }

  let unreadCmAlertsCount = 0;
  let unreadCsidAlertsCount = 0;
  if (cmAlerts && cmAlerts.items) {
    unreadCmAlertsCount = cmAlerts.items.filter(checkAlertUnread).length;
  }
  if (csidAlerts && csidAlerts.items) {
    unreadCsidAlertsCount = csidAlerts.items.filter(checkAlertUnread).length;
  }

  return unreadCmAlertsCount + unreadCsidAlertsCount;
};

export const getAlertName = category => {
  return cmAlertNamesMapping[category]
    ? cmAlertNamesMapping[category]
    : category;
};

export const getValueForAlertDetailsAttribute = (
  title,
  alertDetails,
  category,
  alertFullResponse,
) => {
  switch (title) {
    case 'Account':
      return alertDetails.entityName;
    case 'Employer':
      return alertDetails.entityName;
    case 'Amount':
    case 'Amount past due':
      if (category === cmAlertTypes.ACCOUNT_CHARGE_OFF) {
        return formatIncome(parseInt(alertDetails.accountBalance));
      } else {
        return formatIncome(parseInt(alertDetails.amountDelinquent));
      }
    case 'Status':
      return alertDetails.status &&
        alertDetails.status.toLowerCase() !== 'unknown'
        ? alertDetails.status
        : null;
    case 'Type':
      if (category === cmAlertTypes.PUBLIC_RECORD) {
        return alertDetails.publicRecordType;
      } else if (category === cmAlertTypes.CARD_OVER_LIMIT) {
        return 'Bank card';
      } else if (category === cmAlertTypes.RETAIL_CARD_OVERLIMIT) {
        return 'Retail card';
      }
      return alertDetails.industryType;
    case 'Phone number':
      return formatPhoneNumber(alertDetails.phone);
    case 'Address':
      return formatAddress(alertDetails.address);
    case 'Court name':
      return alertDetails.entityName;
    case 'Liability amount':
      return formatIncome(parseInt(alertDetails.liabilityAmount));
    case 'Reference number':
      return alertDetails.referenceNumber;
    case 'Total balance remaining':
    case 'Total collections balance':
      return formatIncome(parseInt(alertDetails.accountBalance));
    case 'Balance':
    case 'Account balance':
    case 'Amount delinquent':
      if (category === cmAlertTypes.COLLECTIONS_EXCLUDING_MEDICAL) {
        return formatIncome(parseInt(alertDetails.amountDelinquent));
      }
      return formatIncome(parseInt(alertDetails.accountBalance));
    case 'Inquiry date':
    case 'Date added':
      if (
        (category === cmAlertTypes.ENHANCED_NEW_INQUIRY ||
          category === cmAlertTypes.NEW_ACCOUNT) &&
        alertFullResponse
      ) {
        return convertTUDatetoFormattedDate(alertFullResponse.createDate);
      }
      return null;
    case 'Date paid':
    case 'Date reported':
    case 'Foreclosure date':
    case 'Date opened':
      if (alertDetails.dateReported) {
        return category === cmAlertTypes.NEW_AUTO_LEASE ||
          category === cmAlertTypes.PAID_COLLECTION ||
          category === cmAlertTypes.NEW_AUTO_LEASE ||
          category === cmAlertTypes.PAID_COLLECTION ||
          category === cmAlertTypes.NEW_ACCOUNT ||
          category === cmAlertTypes.FRAUD_ALERT ||
          category === cmAlertTypes.ACCOUNT_CHARGE_OFF ||
          category === cmAlertTypes.NEW_AUTHORIZED_USER
          ? convertTUDatetoFormattedDate(alertDetails.dateReported)
          : convertTUDatetoFormattedDate(alertDetails.dateReported, true);
      }
      return null;
    case 'Amount over limit':
      return formatIncome(
        parseInt(alertDetails.accountBalance) -
          parseInt(alertDetails.creditLimit),
      );
    case 'Balance limit':
      return formatIncome(parseInt(alertDetails.accountBalance));
    case 'Credit limit':
      return formatIncome(parseInt(alertDetails.creditLimit));
    case 'Date closed':
    case 'Closing date':
      if (alertDetails.closingDate) {
        return category === cmAlertTypes.NEW_AUTO_LEASE ||
          category === cmAlertTypes.PAID_COLLECTION
          ? convertTUDatetoFormattedDate(alertDetails.closingDate)
          : convertTUDatetoFormattedDate(alertDetails.closingDate, true);
      }
      return null;
    case 'Account number':
      return alertDetails.accountNumber;
    case '__scoreFactors':
      if (
        category === cmAlertTypes.CREDIT_SCORE_INCREASE ||
        category === cmAlertTypes.CREDIT_SCORE_DECREASE
      ) {
        if (alertDetails.tuAdditionalInformation) {
          return getScoreFactors(
            alertDetails.tuAdditionalInformation,
            category,
          );
        }
      }
      return null;

    default:
      return null;
  }
};

function getScoreFactors(factorDetail, category) {
  let output = [];
  let matchPattern =
    category === cmAlertTypes.CREDIT_SCORE_INCREASE
      ? 'PositiveScoreFactor'
      : 'NegativeScoreFactor';

  for (let key in factorDetail) {
    if (
      key.indexOf(matchPattern) === 0 &&
      alertScoreFactorsCodesMap[factorDetail[key]]
    ) {
      output.push(alertScoreFactorsCodesMap[factorDetail[key]]);
    }
  }

  return output;
}

export const getConfigForAlert = (alertType, userInfo) => {
  // const { alertDetails } = alertResponse || {};

  let delinquentCategoryDetailTypes = [
    'Account',
    'Account number',
    'Amount past due',
    'Status',
    'Type',
    'Address',
    'Phone number',
  ];

  let scoreChangeDetailTypes = ['__scoreFactors'];
  let newAccountDetailTypes = [
    'Date added',
    'Account',
    'Account number',
    'Type',
    'Address',
  ];
  let paidAccountDetailTypes = [
    'Account',
    'Account number',
    'Address',
    'Phone number',
    'Credit limit',
  ];
  let improvedAccountDetailTypes = [
    'Account',
    'Account number',
    'Balance',
    'Status',
    'Type',
    'Address',
    'Phone number',
  ];

  let collectionBalancesDetailTypes = ['Total collections balance'];
  let collectionsExcludingMedicalDetailTypes = [
    'Account',
    'Amount past due',
    'Phone number',
  ];
  let accountClosedDetailTypes = ['Date closed', 'Account', 'Credit limit'];
  let cardOverLimiDetailTypes = ['Account', 'Balance', 'Credit limit', 'Type'];

  // if (isActiveNewNotificationCenter) {
  //   delinquentCategoryDetailTypes = [
  //     'Account',
  //     'Account number',
  //     'Amount past due',
  //     'Status',
  //     'Type',
  //     'Address',
  //     'Phone number',
  //   ];
  //   scoreChangeDetailTypes = ['__scoreFactors'];
  // }

  const newAddressActionType = !isUserEnrolledInCash(userInfo)
    ? ['NEW_ADDRESS']
    : null;
  const getAlertNameCopy = c => getAlertName(c);

  switch (alertType) {
    case cmAlertTypes.DELINQUENT_ACCOUNT:
      return {
        title: getAlertNameCopy(cmAlertTypes.DELINQUENT_ACCOUNT),
        subTitle: 'Your account is 30 days or more past due',
        detailTitles: delinquentCategoryDetailTypes,
        description: 'Your account is 30 days or more past due.',
        footerType: 'Payment History',
        actionTypes: ['ACCOUNT_DETAILS'],
        lineType: 'negative',
      };
    case cmAlertTypes.IMPROVED_ACCOUNT:
      return {
        title: getAlertNameCopy(cmAlertTypes.IMPROVED_ACCOUNT),
        subTitle:
          'This account changed from a derogatory status to a [xxx] status. Nicely done!',
        imageLink: '',
        detailTitles: improvedAccountDetailTypes,
        header: 'Good News!',
        description:
          'This account changed from a derogatory status to a current status. Nicely done!',
        footerType: 'Payment History',
        image: true,
        lineType: 'positive',
        actionTypes: ['ACCOUNT_DETAILS_IMPROVED'],
      };
    case cmAlertTypes.BANKRUPTCY:
      return {
        title: getAlertNameCopy(cmAlertTypes.BANKRUPTCY),
        subTitle: 'This account is included as part of your bankruptcy filing',
        detailTitles: ['Account', 'Type'],
        actionTypes: ['ACCOUNT_DETAILS'],
        lineType: 'negative',
      };
    case cmAlertTypes.PUBLIC_RECORD:
      return {
        title: getAlertNameCopy(cmAlertTypes.PUBLIC_RECORD),
        subTitle: 'A new public record has been added to your credit report',
        detailTitles: [
          'Date reported',
          'Court name',
          'Status',
          'Type',
          'Closing date',
          'Reference number',
        ],
        description:
          'A new public record item has been added to your TransUnion® credit report.',
        footerType: 'Payment History',
        lineType: 'negative',
        actionTypes: ['ACCOUNT_DETAILS'],
      };
    case cmAlertTypes.DECREASE_IN_COLLECTION_BALANCES:
      return {
        title: getAlertNameCopy(cmAlertTypes.DECREASE_IN_COLLECTION_BALANCES),
        subTitle: 'Your total collections balance went down. Nicely done!',
        imageLink: '',
        detailTitles: collectionBalancesDetailTypes,
        header: 'Keep up the good work!',
        description:
          'You’re making progress paying down one of your collections account.',
        footerType: 'Payment History',
        image: true,
        lineType: 'positive',
        actionTypes: ['ACCOUNT_DETAILS_IMPROVED'],
      };
    case cmAlertTypes.INCREASE_IN_COLLECTION_BALANCES:
      return {
        title: getAlertNameCopy(cmAlertTypes.INCREASE_IN_COLLECTION_BALANCES),
        subTitle: 'Your total collections balance went up',
        detailTitles: collectionBalancesDetailTypes,
        description: 'Your collections balance went up.',
        footerType: 'Payment History',
        lineType: 'negative',
        actionTypes: ['ACCOUNT_DETAILS'],
      };
    case cmAlertTypes.ACCOUNT_CHARGE_OFF:
      return {
        title: getAlertNameCopy(cmAlertTypes.ACCOUNT_CHARGE_OFF),
        subTitle: 'This account is listed as a "charge-off"',
        subDescription:
          "This means you've missed a number of payments but are still responsible for the debt.",
        detailTitles: ['Date reported', 'Account', 'Amount past due'],
        description:
          'This account is listed as a "charge off," which means you\'ve missed a number of payments but are still responsible for the debt.',
        footerType: 'Payment History',
        lineType: 'negative',
        actionTypes: ['ACCOUNT_DETAILS'],
      };
    case cmAlertTypes.FORCLOSURE:
      return {
        title: getAlertNameCopy(cmAlertTypes.FORCLOSURE),
        subTitle:
          'This mortgage is in foreclosure because payment is 120 days or more past due',
        detailTitles: ['Foreclosure date', 'Account', 'Balance'],
        description:
          'This mortgage is in foreclosure because payment on this account is 120 days or more past due.',
        footerType: 'Payment History',
        lineType: 'negative',
        actionTypes: ['ACCOUNT_DETAILS'],
      };
    case cmAlertTypes.COLLECTIONS_EXCLUDING_MEDICAL:
      return {
        title: getAlertNameCopy(cmAlertTypes.COLLECTIONS_EXCLUDING_MEDICAL),
        subTitle:
          'A new non-medical collection account has been added to your credit report',
        detailTitles: collectionsExcludingMedicalDetailTypes,
        description:
          'A new non-medical collections account has been added to your TransUnion® credit report.',
        footerType: 'Payment History',
        lineType: 'negative',
        actionTypes: ['ACCOUNT_DETAILS'],
      };
    case cmAlertTypes.PAID_COLLECTION:
      return {
        title: getAlertNameCopy(cmAlertTypes.PAID_COLLECTION),
        subTitle: "You've paid off this collection account. Congratulations!",
        imageLink: '',
        detailTitles: ['Date paid', 'Account'],
        header: 'Congratulations!',
        description: "You've paid off this collections account. Awesome job!",
        image: true,
        lineType: 'positive',
        actionTypes: ['ACCOUNT_DETAILS_IMPROVED'],
        showImage: true,
      };
    case cmAlertTypes.CARD_OVER_LIMIT:
      return {
        title: getAlertNameCopy(cmAlertTypes.CARD_OVER_LIMIT),
        subTitle:
          "You're over your credit limit [xxx] on this account You are [xxx] over the credit limit on this card",
        detailTitles: cardOverLimiDetailTypes,
        description: "You're over your credit limit on this account.",
        footerType: 'Credit Usage',
        lineType: 'negative',
        actionTypes: ['CREDIT_USAGE'],
      };
    case cmAlertTypes.PAID_TRADELINE:
      return {
        title: getAlertNameCopy(cmAlertTypes.PAID_TRADELINE),
        subTitle: 'You paid this account down to $0. Nicely done!',
        imageLink: '',
        detailTitles: paidAccountDetailTypes,
        header: 'Nicely done!',
        description: 'You paid this account down to $0.',
        footerType: 'Credit Usage',
        actionTypes: ['CREDIT_USAGE'],
        image: true,
        lineType: 'positive',
      };
    case cmAlertTypes.RETAIL_CARD_OVERLIMIT:
      return {
        title: getAlertNameCopy(cmAlertTypes.RETAIL_CARD_OVERLIMIT),
        subTitle: "You're over your credit limit [xxx] on this account",
        detailTitles: cardOverLimiDetailTypes,
        description: 'One of your cards recently went over its limit.',
        footerType: 'Credit Usage',
        lineType: 'negative',
        actionTypes: ['CREDIT_USAGE'],
      };
    case cmAlertTypes.NEW_AUTHORIZED_USER:
      return {
        title: getAlertNameCopy(cmAlertTypes.NEW_AUTHORIZED_USER),
        subTitle: 'A new authorized user has been added to this account',
        detailTitles: ['Date reported', 'Account', 'Balance', 'Credit limit'],
        lineType: 'neutral',
      };
    case cmAlertTypes.NEW_ACCOUNT:
      return {
        title: getAlertNameCopy(cmAlertTypes.NEW_ACCOUNT),
        detailTitles: newAccountDetailTypes,
        subTitle: 'A new account has been added to your credit report',
        description: 'A new account was just added to your credit report.',
        footerType: 'Credit Age',
        actionTypes: ['ACCOUNT_MIX'],
        lineType: 'negative',
      };
    case cmAlertTypes.CLOSED_ACCOUNT:
      return {
        title: getAlertNameCopy(cmAlertTypes.CLOSED_ACCOUNT),
        subTitle: 'This account has been closed',
        detailTitles: accountClosedDetailTypes,
        description: 'This account has been closed.',
        footerType: 'Credit Age',
        lineType: 'negative',
        actionTypes: ['ACCOUNT_MIX'],
      };
    case cmAlertTypes.MISSING_CARD:
      return {
        title: getAlertNameCopy(cmAlertTypes.MISSING_CARD),
        subTitle: 'You reported this card as lost or stolen',
        detailTitles: ['Date closed', 'Account', 'Account number'],
        description: 'You reported this card as missing.',
        lineType: 'neutral',
      };
    case cmAlertTypes.NEW_AUTO_LEASE:
      return {
        title: getAlertNameCopy(cmAlertTypes.NEW_AUTO_LEASE),
        subTitle: 'A new auto lease has been added to your credit report',
        detailTitles: ['Date opened', 'Account'],
        description:
          'A new auto loan has been added to your TransUnion® credit report.',
        footerType: 'Credit Age',
        actionTypes: ['AUTO_INSURANCE'],
        lineType: 'neutral',
      };
    case cmAlertTypes.NEW_INQUIRY:
      return {
        title: getAlertNameCopy(cmAlertTypes.NEW_INQUIRY),
        subTitle: 'A new inquiry has been added to your credit report',
        detailTitles: ['Account', 'Type', 'Address', 'Phone number'],
        description: 'A new inquiry was just added to your credit report.',
        footerType: 'Credit Inquiries',
        actionTypes: ['CREDIT_INQUIRIES'],
        lineType: 'negative',
      };
    case cmAlertTypes.ENHANCED_NEW_INQUIRY:
      return {
        title: getAlertNameCopy(cmAlertTypes.ENHANCED_NEW_INQUIRY),
        subTitle: 'A new inquiry has been added to your credit report',
        detailTitles: [
          'Inquiry date',
          'Account',
          'Type',
          'Address',
          'Phone number',
        ],
        description: 'A new inquiry was just added to your credit report.',
        footerType: 'Credit Inquiries',
        actionTypes: ['CREDIT_INQUIRIES'],
        lineType: 'negative',
      };
    case cmAlertTypes.NEW_ADDRESS:
      return {
        title: getAlertNameCopy(cmAlertTypes.NEW_ADDRESS),
        subTitle: 'A new address has been added to your credit report',
        detailTitles: ['Address'],
        description:
          'A new address has been added to your TransUnion® credit report.',
        lineType: 'neutral',
        actionTypes: newAddressActionType,
      };
    case cmAlertTypes.NEW_EMPLOYER:
      return {
        title: getAlertNameCopy(cmAlertTypes.NEW_EMPLOYER),
        subTitle: 'A new employer has been added to your credit report ',
        detailTitles: ['Employer', 'Address'],
        description:
          'A new employer has been added to your TransUnion® credit report.',
        lineType: 'neutral',
      };
    case cmAlertTypes.FRAUD_ALERT:
      return {
        title: getAlertNameCopy(cmAlertTypes.FRAUD_ALERT),
        detailTitles: ['Date reported'],
        subTitle: 'You placed a fraud alert on your  credit report',
        subDescription:
          'This means creditors will take extra steps to verify your identity before extending credit to you',
        actionText: cmAlertTypes.FRAUD_ALERT,
        lineType: 'neutral',
      };
    case cmAlertTypes.CREDIT_SCORE_INCREASE:
      return {
        title: getAlertNameCopy(cmAlertTypes.CREDIT_SCORE_INCREASE),
        detailTitles: scoreChangeDetailTypes,
        imageLink: '',
        header: 'Nicely done!',
        description:
          'Your TransUnion® VantageScore 3.0 went up at least 10 points.',
        footerType: 'Score Factors',
        actionTypes: ['SCORE_FACTORS'],
        image: true,
        lineType: 'positive',
      };
    case cmAlertTypes.CREDIT_SCORE_DECREASE:
      return {
        title: getAlertNameCopy(cmAlertTypes.CREDIT_SCORE_DECREASE),
        detailTitles: scoreChangeDetailTypes,
        imageLink: '',
        description:
          'Your TransUnion® VantageScore 3.0 has dropped at least 10 points.',
        footerType: 'My Actions',
        actionTypes: ['SCORE_FACTORS', 'MY_ACTIONS'],
        image: true,
        lineType: 'negative',
      };
    case cmAlertTypes.REPORT_FROZEN:
      return {
        title: getAlertNameCopy(cmAlertTypes.REPORT_FROZEN),
        imageLink: '',
        description: 'Your credit lock status is: Frozen',
        subDescription:
          'Your credit report is frozen at TransUnion. Request TransUnion to lift the credit freeze to start using Credit Report Lock.',
        footerType: 'REPORT_FROZEN',
        actionTypes: ['REPORT_FROZEN'],
        image: true,
        lineType: 'negative',
      };
    case cmAlertTypes.REPORT_UNFROZEN:
      return {
        title: getAlertNameCopy(cmAlertTypes.REPORT_UNFROZEN),
        imageLink: '',
        description: 'Your credit lock status is: Unfrozen',
        subDescription:
          'Your credit report has been unfrozen at TransUnion. You are now able to start using Credit Report Lock to prevent access from identity thieves and fraudsters.',
        footerType: 'REPORT_UNFROZEN',
        actionTypes: ['REPORT_UNFROZEN'],
        image: true,
        lineType: 'negative',
      };
    case cmAlertTypes.REPORT_LOCKED:
      return {
        title: getAlertNameCopy(cmAlertTypes.REPORT_UNFROZEN),
        imageLink: '',
        description: 'Your credit lock status is: Locked',
        subDescription:
          'Your credit report is locked and protected from identity thieves and fraudsters.',
        footerType: 'REPORT_LOCKED',
        actionTypes: ['REPORT_LOCKED'],
        image: true,
        lineType: 'negative',
      };
    case cmAlertTypes.REPORT_UNLOCKED:
      return {
        title: getAlertNameCopy(cmAlertTypes.REPORT_UNFROZEN),
        imageLink: '',
        description: 'Your credit lock status is: Unlocked',
        subDescription:
          'Your credit report is unlocked. Activate credit lock to prevent access from identity thieves and fraudsters.',
        footerType: 'REPORT_UNLOCKED',
        actionTypes: ['REPORT_UNLOCKED'],
        image: true,
        lineType: 'negative',
      };
    default:
      return {
        title: getAlertNameCopy(alertType),
      };
  }
};

export const getPlaceholderValue = (detailsList, placeholder) =>
  detailsList?.find(item => item.title === placeholder)?.value;
