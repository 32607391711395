export const openNewTab = url => {
  window.open(url);
};

export const createWindowReference = _ => window.open();

const getCookie = cname => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getVisitorCookie = () => {
  return getCookie('vid');
};

export const goToUrl = url => {
  window.location.href = url;
};

export const goToUrlWithNewTab = url => {
  window.open(url, '_blank');
};

export const goToPath = path => {
  window.location.href = window.location.origin + path;
};

export function getQueryStringAsMap() {
  const searchString = window.location.search;
  const query = {};
  const pairs = (
    searchString[0] === '?' ? searchString.substr(1) : searchString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i]?.split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const completeUrl = url => {
  const { protocol, host } = window.location;
  let newUrl = url;
  if (url.indexOf(host) === -1 && url.indexOf(protocol) === -1) {
    newUrl = `${protocol}//${host}${url}`;
  } else if (url.indexOf(protocol) === -1) {
    newUrl = `${protocol}//${url}`;
  }
  return newUrl;
};

export const getGetParam = (parameterName, urlStr) => {
  const url = urlStr ? new URL(urlStr) : window.location;

  var result = null,
    tmp = [];
  url.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

export const getGetBooleanParam = (
  parameterName,
  urlStr,
  canReturnNull = false,
) => {
  const result = getGetParam(parameterName, urlStr);
  if (result === 'true') {
    return true;
  } else if (result === 'false' || !canReturnNull) {
    return false;
  } else {
    return result;
  }
};

export const hasHashParam = (parameterName, urlStr) => {
  const url = urlStr ? new URL(urlStr) : window.location;
  return url.hash && url.hash === `#${parameterName}`;
};

export const createFormAndPostToUrl = (path, data, options) => {
  let finalOptions = options;
  if (options === undefined) {
    finalOptions = {};
  }

  var method = finalOptions.method || 'post';

  var form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  const constructElements = item => {
    for (var key in item) {
      if (item[key] != null) {
        var hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', item[key]);
        form.appendChild(hiddenField);
      }
    }
  };

  if (Object.prototype.toString.call(data) === '[object Array]') {
    for (var i = 0; i < data.length; i++) {
      constructElements(data[i]);
    }
  } else {
    constructElements(data, '');
  }
  document.body.appendChild(form);
  form.submit();
};

export const getCurrentUrl = () => window.location.href;

export function hasParentWithClass(element, classname) {
  if (!element || !element.parentNode) {
    return false;
  }
  if (element.classList && element.classList.contains(classname)) {
    return true;
  }
  return hasParentWithClass(element.parentNode, classname);
}

export const scrollToTop = () => window.scrollTo(0, 0);

export function waitForElm(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export const isValidUrl = url => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const formatIdFromText = (text, replaceUnderScroll, replaceNumbers) => {
  let newText = text?.toLowerCase();
  if (replaceUnderScroll) {
    newText = newText.replace(new RegExp(' ', 'g'), '_');
  }
  if (replaceNumbers) {
    newText = newText.replace(/[^a-z0-9_]+/gi, '');
  }
  return newText;
};
