import {
  getNotificationsAlerts,
  getNotificationsAlertsDetails,
} from 'externals/_services/notification.service';
import {
  requestAlerts as requestCsIdAlerts,
  successAlerts as successCsidAlerts,
  failureAlerts as failureCsidAlerts,
} from 'actions/csidAlerts.actions';
import {
  requestAlerts as requestCmAlerts,
  successAlerts as successCmAlerts,
  failureAlerts as failureCmAlerts,
} from 'actions/cmAlerts.actions';
import { wrapService } from 'actions/service_wrapper.actions';
import {
  IDENTITY_TRACE_ALERT,
  SEX_OFFENDER_ALERT,
  COURT_RECORDS_ALERT,
  COURT_RECORDS_REPORT,
  SEX_OFFENDER_REPORT,
  IDENTITY_TRACE_REPORT,
} from 'pages/Alerts/types/csid.constants';

import {
  buildPayloadDispatcher,
  NOTIFICATIONS_ALERTS_PAYLOAD,
  NOTIFICATIONS_ALERTS_DETAILS_PAYLOAD,
} from 'types/payload.constants';

import {
  SELECT_NOTIFICATION_ALERT,
  ACCOUNT_QUARTERLY_REPORT,
} from 'types/notificationsAlerts.constants';
import { saveAlertView } from 'externals/_services/alert.service';

const a_fetchNotificationsDetails = wrapService(getNotificationsAlertsDetails, {
  name: 'fetchNotificationsDetails',
});

const a_fetchNotifications = wrapService(getNotificationsAlerts, {
  name: 'fetchNotifications',
});

const notificationsAlertsPayload = buildPayloadDispatcher(
  'notificationsAlerts',
  NOTIFICATIONS_ALERTS_PAYLOAD,
);

const a_saveAlertView = wrapService(saveAlertView, {
  name: 'saveAlertView',
});

export const notificationAlertsDetailsPayload = (
  selectedAlertDetails,
  selectedAlertDetailsLoading,
  selectedAlertDetailsError,
) => {
  return {
    type: NOTIFICATIONS_ALERTS_DETAILS_PAYLOAD,
    payload: {
      selectedAlertDetails,
      selectedAlertDetailsLoading,
      selectedAlertDetailsError,
    },
  };
};

export const selectNotificationAlert = selectedAlertId => ({
  type: SELECT_NOTIFICATION_ALERT,
  selectedAlert: selectedAlertId,
});

export const getNotificationsDetails =
  (notificationType, alertId) => dispatch => {
    dispatch(notificationAlertsDetailsPayload(null, true, null));
    return new Promise((resolve, reject) => {
      dispatch(a_fetchNotificationsDetails(notificationType, alertId))
        .then(response => {
          dispatch(
            notificationAlertsDetailsPayload(
              Object.assign(response, { notificationType: notificationType }),
              false,
              null,
            ),
          );
          resolve(response);
        })
        .catch(err => {
          dispatch(notificationAlertsDetailsPayload(null, false, err));
          reject(err);
        });
    });
  };

export const getNotifications =
  (forceStart = false) =>
  (dispatch, getState) => {
    const { notificationsAlertsLoading } = getState().notificationsAlerts;
    if (notificationsAlertsLoading && !forceStart)
      return Promise.resolve('Request already in process');

    dispatch(notificationsAlertsPayload(null, true, null));
    dispatch(requestCmAlerts());
    dispatch(requestCsIdAlerts());

    return new Promise((resolve, reject) => {
      dispatch(a_fetchNotifications())
        .then(response => {
          const filterAndSortedNotifications = response?.notifications?.filter(
            n =>
              (n?.notificationType === ACCOUNT_QUARTERLY_REPORT ||
                n?.alertSubCategory !== 'TIME_SENSITIVE') &&
              n?.alertCategory !== IDENTITY_TRACE_ALERT &&
              n?.alertCategory !== SEX_OFFENDER_ALERT &&
              n?.alertCategory !== COURT_RECORDS_ALERT &&
              n?.alertCategory !== COURT_RECORDS_REPORT &&
              n?.alertCategory !== SEX_OFFENDER_REPORT &&
              n?.alertCategory !== IDENTITY_TRACE_REPORT,
          );

          const cmAlerts = filterAndSortedNotifications?.filter(n => {
            return (
              n?.notificationType === 'CREDIT_MONITORING' ||
              n?.notificationType === 'CUSTOM_CREDIT_MONITORING'
            );
          });
          const csidAlerts = filterAndSortedNotifications?.filter(n => {
            return n?.notificationType === 'ID_PROTECTION';
          });

          dispatch(successCsidAlerts({ items: csidAlerts }));
          dispatch(successCmAlerts({ items: cmAlerts }));
          dispatch(
            notificationsAlertsPayload(
              { items: filterAndSortedNotifications },
              false,
              null,
            ),
          );
          resolve(response);
        })
        .catch(err => {
          dispatch(failureCsidAlerts(err));
          dispatch(failureCmAlerts(err));
          dispatch(notificationsAlertsPayload(null, false, err));
          reject(err);
        });
    });
  };

export function setViewNotificationAlert(
  alertId,
  viewStatus,
  notificationType,
) {
  return (dispatch, getState) => {
    const state = getState();
    const { userInfo } = state.userInfo;
    const { notificationsAlerts } = state?.notificationsAlerts;

    if (!userInfo) return Promise.reject('Userinfo missing');

    return new Promise((resolve, reject) => {
      dispatch(a_saveAlertView(alertId, viewStatus, notificationType))
        .then(() => {
          const notificationAlert = notificationsAlerts?.items.map(nAlert => {
            if (nAlert.id === alertId) {
              nAlert.viewed = viewStatus;
            }
            return nAlert;
          });
          dispatch(
            notificationsAlertsPayload(
              { items: notificationAlert },
              false,
              null,
            ),
          );
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}
