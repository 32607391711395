import { updateSessionTimeoutInLocal } from 'helpers/session';
import { getFeaturedAds as getFeaturedAdsData } from 'externals/_services/offers.service';
import { wrapService } from 'actions/service_wrapper.actions';
import { FEATURED_AD_PAYLOAD } from 'types/payload.constants';

const a_getFeaturedAdsData = wrapService(getFeaturedAdsData, {
  name: 'getFeaturedAdsData',
});

function payload(featuredAds, featuredAdsLoading, featuredAdsError) {
  return {
    type: FEATURED_AD_PAYLOAD,
    featuredAds,
    featuredAdsLoading,
    featuredAdsError,
  };
}
function request() {
  return payload(null, true, null);
}

export function __success(featuredAds) {
  return payload(featuredAds, false, null);
}

function failure(error) {
  return payload(null, false, error);
}

export const getFeaturedAds = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    if (state?.featuredAds?.featuredAds) {
      resolve(state?.featuredAds?.featuredAds);
    } else {
      dispatch(request());

      return new Promise((resolve, reject) => {
        dispatch(a_getFeaturedAdsData())
          .then(featuredAds => {
            dispatch(__success(featuredAds));
            updateSessionTimeoutInLocal();
            resolve(featuredAds);
          })
          .catch(err => {
            dispatch(failure(err));
            reject(err);
          });
      });
    }
  });
};

export const getFeaturedAdsForLogin = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(request());

    dispatch(a_getFeaturedAdsData())
      .then(featuredAds => {
        dispatch(__success(featuredAds));
        updateSessionTimeoutInLocal();
        resolve(featuredAds);
      })
      .catch(err => {
        dispatch(failure(err));
        reject(err);
      });
  });
};
